* {
  box-sizing: border-box;
  font-family: 'Work Sans', sans-serif;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  overscroll-behavior: contain;
}

html {
  font-size: 16px;
  margin: 0;
  padding: 0;
  text-decoration-skip-ink: "auto";
  overscroll-behavior: contain;
  background-color: rgb(255, 226, 196);
}

body {
  overscroll-behavior: contain !important;
  overflow: hidden;
  background-color: rgb(255, 226, 196);
}

/* Landing page css */



#root {
  background: rgb(185, 255, 238);
  background: linear-gradient(rgb(185, 255, 238), rgb(255, 226, 196));
  /* overflow-y: scroll; */
  /* height: 100vh; */
}

.copyright {
  font-size: 0.75em;
  position: absolute;
  bottom: 1em;
  right: 1em;
}

.smallText {
  font-size: 0.5em;
}

#back-to-top {

}

#back-to-top, #back-to-top-mobile {
  cursor: pointer;
}

.options {
  display: flex;
  justify-content: space-between;
}

/* .css-2b097c-container > div:first-child > * {
  height: 3em;
} */

#back-to-top-mobile {
  display: none;
}

.option {
  border: 1px solid gray;
  border-radius: 1em;
  padding: 1em;
  width: calc(50% - 0.5em);
  margin: 0.5em;
  font-size: 1.5em !important;
  cursor: pointer;
  transition: 0.3s;
}

.option:first-child {
  margin-left: 0;
}

.option:last-child {
  margin-right: 0;
}

.option:hover {
  background-color: rgb(252, 204, 194);
}

.option-selected {
  background-color: rgb(252, 204, 194);
}

.option > p {
  margin: 0;
  text-align: center;
}

.a > * {
  margin: 0 0 2rem;
}

.big-link {
  text-decoration: underline;
  font-weight: normal;
  text-align: right;
  font-size: 1.25em;
  cursor: pointer;
  margin: 0 0 1em;
}

.text-align-right {
  text-align: right;
}

.align-end {
  align-items: flex-end;
}

.share-btn {
  height: 4em;
  background: black;
  border-radius: 5em;
  float: right;
  width: calc(66% - 2em);
  margin: 1em 0 1em;
  min-width: 15em;
  cursor: pointer;
}

.submit-p {
  cursor: pointer;
}

.share-btn:hover > p,
.social-close-btn:hover > p {
  color: rgb(252, 204, 194);
}

.interactive {
  height: 6em;
}

.phone-input {
  border-radius: 0 !important;
  outline: none;
  background: none;
  border: none;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  height: 2em;
  width: 10em;
  padding: 1em 0.5em;
  font-size: 1.5em !important;
  box-sizing: border-box;
  margin-top: 0.6em;
}

input[type=checkbox] {
  margin-right: 10px;
}

.checkbox-div {
  margin: 0.5em;
}

.phone-input:focus {
  outline: none;
}

.country-container {
  display: flex;
}

.country-container > p {
  font-size: 2em;
}

.country-input {
  font-size: 3em;
  width: 3em;
  border-radius: 0 !important;
  outline: none;
  background: none;
  border: none;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  height: 2em;
  padding: 1em 0.5em;
  font-size: 1.5em !important;
  box-sizing: border-box;
}

.phone-container {
  display: flex;
}

.phone-container > p {
  font-size: 2em;
  width: 25%;
}

.share-btn > p {
  color: white;
  font-weight: bold;
  font-size: 1.25em !important;
  transition: 0.3s;
}

.catch-div {
  height: 4em;
  border: 3px solid black;
  border-radius: 15px;
  width: 10em;
  cursor: pointer;
}

.catch-div > p {
  font-size: 1.5em !important;
  font-weight: bold;
  letter-spacing: 0.25em;
}

.page {
  /* background: rgb(185, 255, 238); */
  overflow: scroll;
  /* background: linear-gradient(rgb(185, 255, 238), rgb(255, 226, 196)); */
  position: relative;
  padding: 1em;
  /* min-height: 100vh; */
  /* max-width: 920px; */
  margin-left: auto;
  margin-right: auto;
  /* border-radius: 30px; */
  margin: 0 auto;
  /* width: calc(100vw - 2em); */
}

.landing-content {
  margin-top: 6em;
  /* height: calc(100vh - 6em); */
  overflow-y: scroll;
  width: calc(100vw - 2.7em);
}

.landing-content > * {
  max-width: calc(920px - 2em);
  margin: 0 auto;
}

.scroll-section {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* height: calc(100vh - 6em); */
  font-size: 20px;
  padding-top: 1.5em;
  margin: 0 0 6em;
}

.text-box, .step-columns {
  font-size: 20px !important;
}

.step-columns > * {
  font-size: 20px !important;
}

/* .width-75 {
  width: 75%;
} */

.text-box > * {
  margin-top: 1em;
  margin-bottom: 1em;
}

.step-columns {
  margin-top: 1em;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.page1-container {
  margin-top: 1em;
  margin-bottom: 1em;
  /* height: calc(100vh - 6em); */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content auto auto auto;
  grid-template-areas:
    "a b"
    "c c"
    "d d"
    "e e"
}

.page2-container {
  grid-area: d;
  height: calc(100vh - 7em);
}

.d {
  grid-area: d;
}

.underline {
  text-decoration: underline;
}

.e {
  margin-bottom: 4em;
  grid-area: e;
}

.e > * {
  margin-top: 1em;
  margin-bottom: 1em;
}

.back {
  font-size: 2em;
  cursor: pointer;
}

.flex {
  display: flex;
}

.margin-0 {
  margin: 0;
}

.post-submit-container {
  font-size: 1.5em !important;
}

.bold {
  font-weight: bold;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.a {
  font-size: 1.5em !important;
  grid-area: a;
}

.b {
  grid-area: b;
}

.c {
  grid-area: c;
}

.change-question-container {
  position: static;
}

.back-btn {
  position: absolute;
  left: -10em;
  top: 50%;
  transform: translateY(-50%);
}

.back-btn > img, .next-btn > img {
  height: 5em;
  width: auto;
  cursor: pointer;
}

.next-btn {
  position: absolute;
  right: -10em;
  top: 50%;
  transform: translateY(-50%);
}

.app-page {
  position: relative;
}

form {
  position: static;
}

.radio-container {
  position: absolute;
  bottom: 0;
  width: 100%;
}

h1, .app-page h2 {
  text-align: center;
}

/* .css-2b097c {
  max-height: 2em;
}

.css-1hwfws3 {
  max-height: 2em;
} */

.modal > p {
  margin: 1em 0;
}

@media only screen and (max-width: 600px) {
  .radio-container {
    position: static;
  }

  #back-to-top {
    display: none;
  }

  #back-to-top-mobile {
    display: block;
    font-size: 2em;
  }

  /* h2 {
    font-size: 1.25em;
  } */

  .page div {
    font-size: 12px;
  }

  .landing-content {
    font-size: 20px;
  }

  .question div, .share-btn > p, .link {
    font-size: 16px !important;
  }

  form {
    font-size: 16px;
  }

  .css-2b097c-container * {
    font-size: 16px !important;
  }

  input, select {
    font-size: 16px;
  }

  .step-columns {
    grid-template-columns: 2fr;
    grid-template-rows: auto auto;
    font-size: 20px;
  }

  .app-page {
    position: static;
  }

  .back-btn, .next-btn {
    position: static;
    transform: none;
  }

  .change-question-container {
    position: absolute;
    bottom: 0;
  }
  
  .options {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .option {
    width: 100%;
    margin: 0 0 1em;
  }

  .page1-container {
    min-height: calc(100% - 2em);
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "a"
      "b"
      "c";
  }
  .page2-container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 6em auto;
    grid-template-areas:
      "d d"
      "e e";
  }

  /* .c {
    margin-bottom: 4em;
  } */
}

.gray-screen {
  background-color: rgba(150, 150, 150, 0.5);
  height: 100%;
  width: 100vw;
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
}

/* Chrome, Safari, Edge, Opera
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* input[type=number] {
  -moz-appearance: textfield;
} */

/* animations */

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate(-150%, -50%);
    transform: translate(-150%, -50%);
    opacity: 0;
  }

  to {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    opacity: 1;
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate(-50%, -150%);
    transform: translate(-50%, -150%);
    opacity: 0;
  }

  to {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate(-50%, 150%);
    transform: translate(-50%, 150%);
    opacity: 0;
  }

  to {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate(-50%, 150%);
    transform: translate(-50%, 150%);
    opacity: 0;
  }

  to {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

.slideInUp {
  -webkit-transform: translateY(150%);
  transform: translateY(150%);
  opacity: 0;
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

nav {
  position: fixed;
  z-index: 2;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
  padding: 1em 1em 0;
  /* box-sizing: content-box;
  background-color: black; */
  /* background-color: rgb(185, 255, 238); */
  /* border-bottom: 3px solid white; */
}

/* nav * {
  color: white;
}

.catch-div {
  border-color: white;
} */

.social-close-btn {
  cursor: pointer;
  background: black;
  border-radius: 4em;
  height: 3em;
  margin-top: 1em;
}

.social-close-btn > p {
  transition: 0.3s;
  color: white;
}

strong {
  margin: 0 0.25em;
}

nav > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: calc(920px - 2em);
}

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 1;
  }

  to {
    -webkit-transform: translate(150%);
    transform: translateY(150%);
    opacity: 0;
  }
}

@keyframes slideOutDown {
  from {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  to {
    -webkit-transform: translate(-50%, 150%);
    transform: translate(-50%, 150%);
    opacity: 0;
  }
}

/* social buttons */

.social {
  min-width: 300px;
  z-index: 3;
  background: white;
  border-radius: 15px;
  padding: 1em;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.social-close-btn {
  cursor: pointer;
  background: black;
  border-radius: 4em;
  margin-top: 1em;
}

.social-close-btn > p {
  transition: 0.3s;
  color: white;
}

.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block;
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
  margin: 0.5em;
}

.resp-sharing-button {
  border-radius: 5px;
  transition: 25ms ease-out;
  padding: 0.5em 0.75em;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.resp-sharing-button__icon svg {
  width: 1em;
  height: 1em;
  margin-right: 0.4em;
  vertical-align: top;
}

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle;
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
  stroke: #fff;
  fill: none;
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
}

.resp-sharing-button--twitter:hover {
  background-color: #2795e9;
}

.resp-sharing-button--pinterest {
  background-color: #bd081c;
}

.resp-sharing-button--pinterest:hover {
  background-color: #8c0615;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
}

.resp-sharing-button--facebook:hover {
  background-color: #2d4373;
}

.resp-sharing-button--tumblr {
  background-color: #35465c;
}

.resp-sharing-button--tumblr:hover {
  background-color: #222d3c;
}

.resp-sharing-button--reddit {
  background-color: #5f99cf;
}

.resp-sharing-button--reddit:hover {
  background-color: #3a80c1;
}

.resp-sharing-button--google {
  background-color: #dd4b39;
}

.resp-sharing-button--google:hover {
  background-color: #c23321;
}

.resp-sharing-button--linkedin {
  background-color: #0077b5;
}

.resp-sharing-button--linkedin:hover {
  background-color: #046293;
}

.resp-sharing-button--email {
  background-color: #777;
}

.resp-sharing-button--phone {
  background-color: rgb(63, 176, 50);
}

.resp-sharing-button--phone:hover {
  background-color: rgb(43, 156, 30);
}

.resp-sharing-button--email:hover {
  background-color: #5e5e5e;
}

.resp-sharing-button--xing {
  background-color: #1a7576;
}

.resp-sharing-button--xing:hover {
  background-color: #114c4c;
}

.resp-sharing-button--whatsapp {
  background-color: #25d366;
}

.resp-sharing-button--whatsapp:hover {
  background-color: #1da851;
}

.resp-sharing-button--hackernews {
  background-color: #ff6600;
}
.resp-sharing-button--hackernews:hover,
.resp-sharing-button--hackernews:focus {
  background-color: #fb6200;
}

.resp-sharing-button--vk {
  background-color: #507299;
}

.resp-sharing-button--vk:hover {
  background-color: #43648c;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998;
}

.resp-sharing-button--facebook:hover,
.resp-sharing-button--facebook:active {
  background-color: #2d4373;
  border-color: #2d4373;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
  border-color: #55acee;
}

.resp-sharing-button--twitter:hover,
.resp-sharing-button--twitter:active {
  background-color: #2795e9;
  border-color: #2795e9;
}

.resp-sharing-button--tumblr {
  background-color: #35465c;
  border-color: #35465c;
}

.resp-sharing-button--tumblr:hover,
.resp-sharing-button--tumblr:active {
  background-color: #222d3c;
  border-color: #222d3c;
}

.resp-sharing-button--email {
  background-color: #777777;
  border-color: #777777;
}

.resp-sharing-button--email:hover,
.resp-sharing-button--email:active {
  background-color: #5e5e5e;
  border-color: #5e5e5e;
}

.resp-sharing-button--pinterest {
  background-color: #bd081c;
  border-color: #bd081c;
}

.resp-sharing-button--pinterest:hover,
.resp-sharing-button--pinterest:active {
  background-color: #8c0615;
  border-color: #8c0615;
}


/* Survey css */


.app-catch-div {
  height: 4em;
  border: 3px solid black;
  border-radius: 15px;
  width: 10em;
  display: none !important;
}

.margin-top-bottom-05em {
  margin: 0.5em 0;
}

h4 {
  margin: 0.5em 0;
}

.title-container {
  border-bottom: 1px solid rgb(204, 204, 204);
}

.app-catch-div > p {
  font-size: 1.5em !important;
  font-weight: bold;
  letter-spacing: 0.25em;
}

.vertical-btn-container {
  height: 9em;
}

.modal {
  border-radius: 15px;
  background: white;
  border: 2px solid black;
  padding: 1em;
  z-index: 3;
  min-width: 250px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.full-modal {
  max-width: 500px !important;
}

.full-modal .btn-div:first-child {
  width: 100% !important;
  margin: 0.5em 0 0.5em;
}

.full-modal .btn-div:last-child {
  width: 100% !important;
  margin: 0.5em 0 0 0;
}

.Demo__clear-button {
  display: none;
}

.modal > * {
  color: black;
}

label {
  margin: 0.25em 0;
}

footer > .big-link {
  margin-bottom: 0.25em !important;
}

.app-page {
  padding: 1em;
  max-width: 375px;
  min-height: calc(100vh - 10.25em);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4em;
  border: 3px solid black;
  border-radius: 30px;
  background-color: white;
}

.question h4 {
  font-size: 1.25em !important;
  font-weight: normal;
}

.question > label {
  margin: 1em 0;
}

.margin-1em {
  margin: 1em;
}

/* .login-page {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.results-page {
  padding: 1em;
  box-sizing: border-box;
  display: grid !important;
  grid-template-rows: min-content auto;
  grid-gap: 1em;
}

.gray-screen {
  background-color: rgba(200, 200, 200, 0.5);
  height: 100%;
  width: 100vw;
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.btn-container {
  min-width: 20em;
  max-width: 40em;
}

.center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.radio-container {
  display: flex;
  justify-content: space-between;
}

.radio-btn {
  width: 50%;
  position: relative;
  height: 3em;
  border: 2px solid black !important;
  /* border-radius: 15px !important; */
}

.radio-btn:last-child {
  margin-right: 0;
}

.radio-btn > span {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.results-container {
  display: flex;
  align-items: center;
  padding: 1em;
}

form {
  position: relative;
}

.results-container > p {
  color: black;
  font-size: 1.5em !important;
}

.login {
  /* height: 15em; */
  /* width: 75vw; */
  width: 100%;
  height: 100%;
}

.margin-0 {
  margin: 0;
}

.align-center {
  align-items: center;
}

.text-align-center {
  text-align: center;
}

/* .pink {
  transition: 0.3s;
  border: none;
  outline: none;
  background-color: #f4baba !important;
} */

.btn-div:hover,
.pink:hover,
.submit-btn:hover {
  background-color: rgb(205, 247, 226) !important;
}

.cancel-btn {
  background-color: rgb(170, 170, 170);
  transition: 0.3s;
}

.cancel-btn:hover {
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.call-btn {
  text-decoration: none;
  border-radius: 15px;
  font-size: 20px;
  background-color: red;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4em;
  width: 100%;
  margin: 0.5em 0;
}

/* .section {
  margin-bottom: 6em;
} */

.Demo__autocomplete-container * {
  cursor: pointer !important;
}

.Demo__autocomplete-container:hover * {
  cursor: pointer !important;
}

.Demo__suggestion-item:hover {
  cursor: pointer !important;
}

.pointer {
  margin: 0.25em;
  cursor: pointer !important;
}

.question input[type=text], input[type=email], select, .physician-email {
  display: block;
  margin: 0.5em 0.5em 0.5em 0;
  height: 3em;
  border-radius: 10px;
  border: 1px solid black;
  outline: none;
  color: black;
  padding: 0.25em 0.5em;
  width: calc(100% - 4.5em);
  width: 100%;
  font-size: 1em;
}

.physician-email {
  font-size: 1.5em !important;
}

input[name=country_phone] {
  width: 4em !important;
}

.change-question-container {
  width: 100%;
}

.change-question-container > div:first-child {
  margin: 0 0.5em 0 0;
}

.change-question-container > div:last-child {
  margin: 0 0 0 0.5em;
}

.btn-div, .submit-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid black;
  border-radius: 15px;
  width: 50%;
  height: 4em;
  transition: 0.3s;
}

.submit-btn {
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 1em;
}

#red-btn {
  background-color: red;
}

#red-btn > p {
  color: white;
}

#red-btn:hover {
  background-color: rgb(255, 95, 95) !important;
}

/* .change-question-container > button {
  margin: 0 0 0 0.5em !important;
} */

.survey-page {
  padding: 1em;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: minmax(0, auto);
  grid-template-rows: min-content auto;
}

.survey-page > *, .survey-page * {
  max-width: 100%;
}

.survey-page li {
  width: calc(100% - 2em);
  margin: 0.5em 1em;
}

.survey-page .radio-btn {
  width: calc(50% - 0.5em);
  margin-top: 0.5em;
}

.full-width {
  width: 100% !important;
}

.hidden {
  opacity: 0;
  cursor: auto;
}

.btn-div > p, .submit-btn {
  font-size: 1.25rem;
  text-align: center;
  font-weight: normal;
  margin: 0;
  color: rgb(26, 32, 44);
  padding: 0 !important;
}

.modal-btn-container > div:first-child {
  margin-right: 0.5em;
}

.modal-btn-container > div:last-child {
  margin-left: 0.5em;
}

.submit-btn {
  margin: 2em 0 0;
  font-size: 1.25em;
  height: 3.25em;
}

#covid {
  margin: 0;
}

.loader {
  width: 6em;
  transform-origin: center;
  animation: rotate 2s linear infinite;
}

circle {
  fill: none;
  stroke: #6A4D82;
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dashoffset: -125px;
  }
}

.login-page-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.login-page-container:first-child {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.social > div:last-child {
  margin-top: 1em;
}

.social > div > p {
  margin: 0 0.5em 0 0;
}

.social > div > a {
  height: 2em;
}

.twitter-icon {
  fill: #2795e9;
  height: 2em;
  width: 2em;
}

.mail-icon {
  fill: #777;
  height: 2em;
  width: 2em;
  margin-left: 0.5em;
}

.contact-us {
  margin-right: 0.5em;
}

.contact-link {
  color: black;
}

.social-btn-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.social-btn-container > a:first-child {
  margin-left: 0 !important;
}

.social-btn-contianer > a:last-child {
  margin-right: 0 !important;
}

.resp-sharing-button {
  display: flex;
}

.resp-sharing-button__icon {
  display: flex !important;
  justify-content: flex-start;
  align-items: center !important;
}

.social-close-btn {
  cursor: pointer;
  background: black;
  border-radius: 4em;
  margin-top: 1em;
}

.social-close-btn > p {
  transition: 0.3s;
  color: white;
}

.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block;
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
  margin: 0.5em;
}

.resp-sharing-button {
  border-radius: 5px;
  transition: 25ms ease-out;
  padding: 0.5em 0.75em;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.resp-sharing-button__icon svg {
  width: 1em;
  height: 1em;
  margin-right: 0.4em;
  vertical-align: top;
}

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle;
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
  stroke: #fff;
  fill: none;
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
}

.resp-sharing-button--twitter:hover {
  background-color: #2795e9;
}

.resp-sharing-button--pinterest {
  background-color: #bd081c;
}

.resp-sharing-button--pinterest:hover {
  background-color: #8c0615;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
}

.resp-sharing-button--facebook:hover {
  background-color: #2d4373;
}

.resp-sharing-button--tumblr {
  background-color: #35465c;
}

.resp-sharing-button--tumblr:hover {
  background-color: #222d3c;
}

.resp-sharing-button--reddit {
  background-color: #5f99cf;
}

.resp-sharing-button--reddit:hover {
  background-color: #3a80c1;
}

.resp-sharing-button--google {
  background-color: #dd4b39;
}

.resp-sharing-button--google:hover {
  background-color: #c23321;
}

.resp-sharing-button--linkedin {
  background-color: #0077b5;
}

.resp-sharing-button--linkedin:hover {
  background-color: #046293;
}

.resp-sharing-button--email {
  background-color: #777;
}

.resp-sharing-button--email:hover {
  background-color: #5e5e5e;
}

.resp-sharing-button--xing {
  background-color: #1a7576;
}

.resp-sharing-button--xing:hover {
  background-color: #114c4c;
}

.resp-sharing-button--whatsapp {
  background-color: #25d366;
}

.resp-sharing-button--whatsapp:hover {
  background-color: #1da851;
}

.resp-sharing-button--hackernews {
  background-color: #ff6600;
}
.resp-sharing-button--hackernews:hover,
.resp-sharing-button--hackernews:focus {
  background-color: #fb6200;
}

.resp-sharing-button--vk {
  background-color: #507299;
}

.resp-sharing-button--vk:hover {
  background-color: #43648c;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998;
}

.resp-sharing-button--facebook:hover,
.resp-sharing-button--facebook:active {
  background-color: #2d4373;
  border-color: #2d4373;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
  border-color: #55acee;
}

.resp-sharing-button--twitter:hover,
.resp-sharing-button--twitter:active {
  background-color: #2795e9;
  border-color: #2795e9;
}

.resp-sharing-button--tumblr {
  background-color: #35465c;
  border-color: #35465c;
}

.resp-sharing-button--tumblr:hover,
.resp-sharing-button--tumblr:active {
  background-color: #222d3c;
  border-color: #222d3c;
}

.resp-sharing-button--email {
  background-color: #777777;
  border-color: #777777;
}

.resp-sharing-button--email:hover,
.resp-sharing-button--email:active {
  background-color: #5e5e5e;
  border-color: #5e5e5e;
}

.resp-sharing-button--pinterest {
  background-color: #bd081c;
  border-color: #bd081c;
}

.resp-sharing-button--pinterest:hover,
.resp-sharing-button--pinterest:active {
  background-color: #8c0615;
  border-color: #8c0615;
}

.close-btn {
  margin: 0.5em !important;
  background-color: #5e5e5e !important;
  color: white !important;
}

.flex-end {
  justify-content: flex-end;
}

@media only screen and (max-width: 600px) {
  .social-btn-container {
    grid-template-columns: 1fr 1fr;
  }
  .social-btn-container > a:first-child {
    margin-left: 0.5em !important;
  }

  .page {
    margin-bottom: 4em;
  }

  nav {
    padding: 1em;
  }

  .personal-details {
    margin-bottom: 5em;
  }

  .social-btn-container > a:last-child {
    margin-right: 0.5em !important;
  }

  .pos-relative {
    height: 10em;
  }

  .radio-container {
    margin-bottom: 4em;
  }

  .checkbox-container {
    margin-bottom: 5em;
  }

  .scroll-section {
    margin: 0.25em 0 2em;
    /* padding-top: 0.5em; */
  }

  .big-link {
    font-size: 1.25em;
    margin-bottom: 0.25em;
  }

  .submit-btn {
    position: absolute;
    right: 0.5em;
    bottom: 0.5em;
    width: 50%;
  }

  /* .app-catch-div {
    display: block !important;
  } */
  /* .results-container {
    padding: 1em;
  } */
}

#legal_text p {
  font-size: 0.888rem;
}

::-webkit-scrollbar {
  display: none;
}

